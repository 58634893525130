import styled from "styled-components";

const ContactFromWrapper = styled.div`
  display: flex;
  align-items: stretch;
  width: 490px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;

  @media (max-width: 575px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
    button {
      width: 100%;
    }
  }

  .inputs_wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  .company_input { grid-area: 1 / 1 / 2 / 3; }
  .first_input { grid-area: 2 / 1 / 3 / 2; }
  .last_input { grid-area: 2 / 2 / 3 / 3; }
  .email_input { grid-area: 3 / 1 / 4 / 2; }
  .phone_input { grid-area: 3 / 2 / 4 / 3; }
  .submit_button { grid-area: 4 / 1 / 5 / 3; }
  
`;

export default ContactFromWrapper;
