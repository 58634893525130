import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';

import ContactFromWrapper from '../containers/Hosting/Contact/contact.style';
import Button from 'reusecore/src/elements/Button';
import Input from 'reusecore/src/elements/Input';

const Schema = {
  company: {
    label: 'Company Name',
    type: 'text'
  },
  first: {
    label: 'First Name',
    type: 'text'
  },
  last: {
    label: 'Last Name',
    type: 'text'
  },
  email: {
    label: 'Email Address',
    type: 'email'
  },
  phone: {
    label: 'Phone Number',
    type: 'text'
  }  
}

const initialValues = { 
  company: '',
  first: '', 
  last: '',
  email: '',
  phone: ''
}

const ContactForm = ({button}) => {

  const handleSumit = (values, {setSubmitting,resetForm}) => {
    console.log(values);
    axios.post('https://directus.fastpbx.io/_/items/leads', values)
    .then((response) =>{
      setSubmitting(false);
      resetForm(initialValues);
      console.log(values);
    })
    .catch((error) => {
      setSubmitting(false);
      console.log(error.message); 
    });

  }

  return ( 
    <ContactFromWrapper>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSumit}
      >

      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit} className='inputs_wrapper'>

          {console.log(values)}

          {Object.keys(Schema).map((k)=>{
            let f = Schema[k];
            return (
              <Input
                key={k}
                inputType={f.type}
                placeholder={f.label}
                iconPosition="right"
                isMaterial={false}
                className={`${k}_input`}
                name={k}
                id={k}
                aria-label={k}
                onChange={handleChange(k)}
                onBlur={handleBlur(k)}
                value={values[k]}
              />
            )
          })}

          {errors.email && touched.email && errors.email}

          <Button className='submit_button' {...button} type="submit" disabled={isSubmitting} title={isSubmitting?'Sending...':'GET STARTED'} />

        </form>
      )}

      </Formik>
        

    </ContactFromWrapper>
  );

}

ContactForm.propTypes = {
  button: PropTypes.object
};

ContactForm.defaultProps = {
  button: {
    type: 'button',
    fontSize: `${2}`,
    fontWeight: '600',
    borderRadius: '4px',
    width: '100%',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    height: `${4}`,
  }
};

export default ContactForm;